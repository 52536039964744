.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}.discountHeader{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.discountBtn{
display: flex;
flex-direction: row;
}
@media only screen and (max-width: 600px) {

    .discountHeader{
        flex-direction: column;
    }
    .discountRange{
        order: 2;
    }
    .discountBtn{
        justify-content: flex-end;
        margin-bottom: 10px;
        }
}